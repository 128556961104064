<template>
  <div class="homepage">
    <v-container class="category-block-header">
      <category-title :category="category" />
      <div
        v-if="isAuthenticated"
        class="primary--text grey lighten-3 rounded-sm"
      >
        <cart-info-list :isCart="true" />
      </div>
    </v-container>
    <!-- <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />
    <category-block
      :target="category"
      position="position4"
      class="category-block category-block-4"
    />
    <category-block
      :target="category"
      position="position5"
      class="category-block category-block-5"
    />
    <category-block
      :target="category"
      position="position6"
      class="category-block category-block-6"
    />
    <category-block
      :target="category"
      position="position7"
      class="category-block category-block-7"
    />
    <category-block
      :target="category"
      position="position8"
      class="category-block category-block-8"
    />
    <category-block
      :target="category"
      position="position9"
      class="category-block category-block-9"
    />
    <category-block
      :target="category"
      position="position10"
      class="category-block category-block-10"
    /> -->
    <category-block
      v-for="(config, idx) in categoryBlockOrderedByPriority"
      :key="config.categoryBlockId"
      :config="config"
      :class="
        `category-block category-block-position-${idx} category-block-${config.priority}`
      "
    />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>

<style lang="scss">
.homepage {
  .banner-slider {
    .proposal-img {
      .v-image {
        max-height: 400px;
        @media #{map-get($display-breakpoints, 'xl-only')} {
          max-height: 600px;
        }
      }
    }
  }
}
</style>
<script>
import split from "lodash/split";
// // import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions } from "vuex";
import CartInfoList from "@/components/cart/CartInfoList.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    // CartInfoWrapper,
    // CategoryBlock,
    CartInfoList,
    CategoryTitle
  },
  data() {
    return {
      key: 1
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    }),

    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    },
    hideContainer(name) {
      //used to fix slider parent container visibility if no cards inside slider
      this.hidden[name] = true;
    },
    getCategoryBlockOrderedByPriority() {
      try {
        return this.category.categoryBlocks.sort(this.comparePriority);
      } catch (err) {
        console.log(err);
        return this.category.categoryBlocks;
      }
    },
    comparePriority(obj1, obj2) {
      if (obj1.priority < obj2.priority) {
        return -1;
      }
      if (obj1.priority > obj2.priority) {
        return 1;
      }
      return 0;
    }
  },
  computed: {
    alert: {
      get() {
        return this.$store.state.custom.priceDisplayDisclaimer;
      },
      set(value) {
        this.$store.commit("custom/updatePriceDisplayDisclaimer", value);
      }
    },
    categoryBlockOrderedByPriority() {
      return this.getCategoryBlockOrderedByPriority();
    }
  },
  mounted() {
    global.EventBus.$on("login", () => {
      if (this.$vuetify.breakpoint.xs) {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  }
};
</script>
